<template>
  <section class="cms-grid-slider" :style="getStyles">
    <h3
      v-if="title"
      v-once
      class="slider-title"
    >
      {{ $t(title) }}
    </h3>
    <MSfCarouselNext
      ref="carouselRef"
      :key="columns.length"
      :settings="settings"
      :total="columns.length"
    >
      <SfCarouselItem
        v-for="(item, index) in columns"
        :key="index"
      >
        <component
          :is="item.props.component"
          v-bind="{...item.props, observeIndividualItem}"
          :position="index + 1"
          :lazy="true"
          :columns-length="columnsLength"
          :parent-block-id="blockId"
        />
      </SfCarouselItem>
    </MSfCarouselNext>
  </section>
</template>
<script>
import MSfCarouselNext from 'src/common/components/molecules/m-sf-carousel-next.vue';

export default {
  name: 'GridSlider',
  components: {
    MSfCarouselNext,
    card: () => import('src/modules/storyblok/components/Card.vue'),
    overlay_card: () => import('src/modules/storyblok/components/OverlayCard.vue')
  },
  props: {
    columns: {
      type: Array,
      default: () => []
    },
    columnsLength: {
      type: String,
      default: '2'
    },
    title: {
      type: String,
      default: ''
    },
    blockId: {
      type: String,
      default: ''
    },
    observeIndividualItem: {
      type: Boolean,
      default: false
    },
    gridBgColor: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getStyles () {
      return {
        '--grid-bg-color': this.gridBgColor?.color || '#FFFFFF'
      }
    }
  },
  data: () => ({
    glide: null,
    settings: {
      type: 'slider',
      rewind: false,
      slidePerPage: false,
      bound: true,
      perView: 3,
      breakpoints: {
        1279: {
          perView: 2,
          peek: {
            before: 30,
            after: 30
          }
        },
        450: {
          perView: 1
        }
      }
    }
  })
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~@storefront-ui/shared/styles/helpers/typography";
.cms-grid-slider {
  grid-template-columns: 1fr;
  padding: 0;
  background: var(--grid-bg-color);
  gap: 1.875rem;

  .slider-title {
    padding: 0;
    margin: 0;
    @include for-desktop {
      text-align: center;
      margin-bottom: 20px;
    }
  }

  @include for-desktop {
    padding: 0 1.3rem;
    margin-top: 80px;
    grid-template-columns: var(--cms-grid-template-columns, repeat(2, 1fr));
  }

  @include for-mobile {
    margin-top: 40px;
    padding: 20px;
  }
  ::v-deep .sf-product-card {
    --product-card-max-width: 20.5rem;
  }

  ::v-deep .sf-image--has-size {
    max-width: 100%;
  }
  ::v-deep .cms-card {
   --divider-text-only-padding: 0.5px 0px 20px !important;
    &__image,
    &__content-title {
      margin-bottom: 1rem;
    }
    &__content-subtitle {
      margin-bottom: 0.5rem;
    }
    &__content-stext {
      line-height: 1.3rem;
      height: 3.9rem;
      overflow: hidden;
      & p {
        margin: 0;
      }
    }
    & .m-image {
      width: 100%;
    }
  }
}
</style>
