<template>
  <div
    class="sf-carousel"
    :class="{
      'sf-carousel--hide-controls':
        total + viewMore.isVisible <= perView,
    }"
  >
    <div v-if="!hideControls" class="sf-carousel__controls">
      <!--@slot slot for icon moving to the previous item -->
      <button
        class="product-carousel__arrow"
        :disabled="isPrevDisabled"
        :class="{
          'disabled':isPrevDisabled }"
        @click.prevent="go({
          direction: -1
        })"
      >
        <svg class="rotate-180 lb-icon no-fill stroke">
          <use xlink:href="#carousel-arrow" />
        </svg>
      </button>
      <button
        class="product-carousel__arrow"
        :disabled="isNextDisabled"
        :class="{
          'disabled':isNextDisabled }"
        @click.prevent="go({
          direction: 1
        })"
      >
        <svg class="lb-icon no-fill stroke">
          <use xlink:href="#carousel-arrow" />
        </svg>
      </button>
      <!--@slot slot for icon moving to the next item -->
      <slot
        name="next"
        v-bind="{ go: (config) => go(config) }"
      />
    </div>
    <div class="sf-carousel__wrapper">
      <div
        ref="glide"
        class="glide"
      >
        <div
          class="glide__track"
          data-glide-el="track"
        >
          <ul class="glide__slides sf-carousel__slides">
            <!--@slot default slot for SfCarouselItem tags -->
            <slot />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Glide from '@glidejs/glide';
import { isServer } from '@vue-storefront/core/helpers';

export default {
  name: 'MSfCarouselNext',
  props: {
    settings: {
      type: Object,
      default: () => ({})
    },
    total: {
      type: Number,
      default: 0
    },
    viewMore: {
      type: Object,
      default: () => ({
        isVisible: false,
        ctaLink: {
          url: ''
        },
        image: {},
        text: ''
      })
    },
    hideControls: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      glide: null,
      defaultSettings: {}
    };
  },
  computed: {
    isPrevDisabled () {
      return this.glide && this.glide.index === 0;
    },
    isNextDisabled () {
      if (!this.glide) return false;
      return (
        this.glide.index + this.glide.settings.perView >=
        this.total + this.viewMore.isVisible
      );
    },
    perView () {
      if (!isServer && this.glide) {
        try {
          return this.glide?.settings.perView
        } catch (e) {}
      }
      return this.settings.perView;
    },
    mergedOptions () {
      let breakpoints = { ...this.defaultSettings.breakpoints };
      if (this.settings.breakpoints) {
        breakpoints = { ...breakpoints, ...this.settings.breakpoints };
      }
      return {
        ...this.defaultSettings,
        ...this.settings,
        breakpoints: breakpoints
      };
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      if (!this.$slots.default) return;
      const glide = new Glide(this.$refs.glide, this.mergedOptions);
      glide.mount();
      glide.on('run.before', (move) => {
        const { slidePerPage, rewind, type } = this.mergedOptions;
        if (!slidePerPage) return;
        const { perView } = glide.settings;
        if (!perView > 1) return;
        const size = this.$slots.default.filter((slot) => slot.tag).length;
        const { direction } = move;
        let page, newIndex;
        switch (direction) {
          case '>':
          case '<':
            page = Math.ceil(glide.index / perView);
            newIndex =
              page * perView + (direction === '>' ? perView : -perView);
            if (newIndex >= size) {
              if (type === 'slider' && !rewind) {
                newIndex = glide.index;
              } else {
                newIndex = 0;
              }
            } else if (newIndex < 0 || newIndex + perView > size) {
              if (type === 'slider' && !rewind) {
                newIndex = glide.index;
              } else {
                newIndex = size - perView;
              }
            }
            move.direction = '=';
            move.steps = newIndex;
        }
      });
      glide.on('run.after', () => {
        this.$emit('carousel-run-after', { index: this.glide.index })
      })
      glide.on('move.after', () => {
        this.$emit('carousel-moved', { index: this.glide.index })
      })
      this.glide = glide;
    });
  },
  methods: {
    go ({ direction }) {
      if (!this.glide) return;
      const perView = this.glide.settings.perView
      const max = Math.ceil(this.total - perView)
      let slideTo = this.glide.index + (perView * direction)
      slideTo = Math.min(max, slideTo)
      if (slideTo < 0) {
        slideTo = 0
      }
      this.glide.go(`=${slideTo}`)
    },
    goToIndex (index) {
      this.glide.update({
        startAt: index
      })
    }
  }
};
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/components/organisms/SfCarousel.scss";

.rotate-180 {
  transform: rotate(180deg);
}

.sf-carousel {
    --carousel-controls-left: 0;
    --carousel-controls-width: 100%;
    --carousel-width: 100%;
  @include for-mobile {
    --carousel-width: 100%;
    --carousel-controls-width: auto;
    .glide__track {
      margin-top: 22px;
    }
  }
}

.sf-carousel__controls {
  z-index: 3;
  top: var(--carousel-controls-top, 45%);
  @include for-mobile {
    --carousel-controls-width: auto;
    --carousel-controls-display: flex;
    --carousel-controls-transform: translate3d(0, -100%, 0);
    --carousel-controls-top: 0;
    --carousel-controls-left: auto;
    right: 0;
    gap: var(--spacing-md);
  }
  @include for-desktop {
    --carousel-controls-width: calc(100% + 32px);
    --carousel-controls-left: -15px;
  }
}

.product-carousel__arrow {
  background-color: var(--white);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);
  border: none;
  width: 36px;
  height: 36px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    height: 30px;
    width: 30px;
  }
  &.disabled {
    opacity: 0.2;
  }

  @include for-mobile {
    border: 1px solid var(--bold-brown-100);
    box-shadow: none;
  }
}

.sf-carousel--hide-controls {
  .sf-carousel__controls {
    display: none;
  }

  .glide__slides.sf-carousel__slides {
    width: 100% !important;
    @include for-desktop {
      justify-content: center;
    }
  }
}
</style>
