var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sf-carousel",class:{
    'sf-carousel--hide-controls':
      _vm.total + _vm.viewMore.isVisible <= _vm.perView,
  }},[(!_vm.hideControls)?_c('div',{staticClass:"sf-carousel__controls"},[_c('button',{staticClass:"product-carousel__arrow",class:{
        'disabled':_vm.isPrevDisabled },attrs:{"disabled":_vm.isPrevDisabled},on:{"click":function($event){$event.preventDefault();return _vm.go({
        direction: -1
      })}}},[_c('svg',{staticClass:"rotate-180 lb-icon no-fill stroke"},[_c('use',{attrs:{"xlink:href":"#carousel-arrow"}})])]),_vm._v(" "),_c('button',{staticClass:"product-carousel__arrow",class:{
        'disabled':_vm.isNextDisabled },attrs:{"disabled":_vm.isNextDisabled},on:{"click":function($event){$event.preventDefault();return _vm.go({
        direction: 1
      })}}},[_c('svg',{staticClass:"lb-icon no-fill stroke"},[_c('use',{attrs:{"xlink:href":"#carousel-arrow"}})])]),_vm._v(" "),_vm._t("next",null,null,{ go: function (config) { return _vm.go(config); } })],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sf-carousel__wrapper"},[_c('div',{ref:"glide",staticClass:"glide"},[_c('div',{staticClass:"glide__track",attrs:{"data-glide-el":"track"}},[_c('ul',{staticClass:"glide__slides sf-carousel__slides"},[_vm._t("default")],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }